import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
  COOKIE_NAME = "nobi_mobile_dashboard_tab"
  DEFAULT_SECTION = "urgentSection"

  static targets = [
    "tab",
    "navbar",
    "section",
    "urgentSection",
    "techAlertSection",
    "attentionSection",
    "analysisSection",
    "urgentSectionTab",
    "techAlertSectionTab",
    "attentionSectionTab",
    "analysisSectionTab",
  ]
  static classes = ["hidden", "tabSelectedSection"]
  static values = { mobileDevice: Boolean }

  connect() {
    this.initNavbar()
  }

  initNavbar() {
    if (this.mobileDeviceValue || this.navbarTarget.checkVisibility()) {
      this.#change_displayed_section(this.#saved_section_or_default_section())
    }
  }

  selectSection(event) {
    let selectedSection = event.params.selectedSection
    this.#change_displayed_section(selectedSection)
    Cookies.set(this.COOKIE_NAME, selectedSection)
  }

  #change_displayed_section(selectedSection) {
    this.#reset()
    this.#display_selected_section(selectedSection)
  }

  #reset() {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove(...this.tabSelectedSectionClasses)
    })

    this.sectionTargets.forEach((section) => {
      section.classList.add(this.hiddenClass)
    })
  }

  #display_selected_section(selectedSection) {
    this.targets.find(selectedSection).classList.remove(this.hiddenClass)
    this.targets.find(selectedSection + "Tab").classList.add(...this.tabSelectedSectionClasses)
  }

  #saved_section_or_default_section() {
    let section_from_cookie = Cookies.get(this.COOKIE_NAME)
    if (this.targets.has(section_from_cookie)) {
      return section_from_cookie
    } else {
      return this.DEFAULT_SECTION
    }
  }
}

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

/* eslint-disable no-undef */
require("@rails/ujs").start();

import "regenerator-runtime/runtime";
import "@hotwired/turbo-rails";

require("@rails/activestorage").start();
require("channels");

window.$ = window.jQuery = require("jquery");

import("chartkick/chart.js");
require("script-loader!bootstrap-notify");
require("script-loader!../src/flash_messages");
import("dayjs");
import "jquery-scroll-lock";

import "../src/js.cookie.min";
window.Cookies = Cookies;

import "../src/dashmix.app";
import "../src/common.js";
import "../src/serviceworker-companion";
import "../src/timeline";
import "../src/alerts";
import "controllers";
import "../src/confirmation-modal";

import { StreamActions } from "@hotwired/turbo"

StreamActions.redirect_to = function() {
  const url = this.getAttribute("url") || "/"
  const turboAction = this.getAttribute("turbo-action") || "advance"
  const turboFrame = this.getAttribute("turbo-frame")
  const turbo = this.getAttribute("turbo") !== "false"
  const options = {
    action: turboAction
  }

  if (turboFrame) {
    options.frame = turboFrame
  }

  if (turbo && window.Turbo) {
    window.Turbo.visit(url, options)
  } else {
    Proxy.location.assign(url)
  }
}

/* eslint-enable no-undef */

import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = [ "list", "callOrderInput" ]

  connect() {
    this.sortable = Sortable.create(this.listTarget, {
      onEnd: this.saveOrder.bind(this),
      handle: ".sort-handle",
      animation: 500,
      ghostClass: "tw-opacity-50",
      filter: ".btn, .inactive",
      draggable: ".block:not(.inactive), tr:not(.inactive)"
    })
  }

  disconnect() {
    this.sortable.destroy()
  }

  saveOrder({ item: { dataset: { url }}, newIndex }) {
    this.callOrderInputTarget.value = newIndex + 1
    this.callOrderInputTarget.form.action = url
    this.callOrderInputTarget.form.requestSubmit()
  }
}

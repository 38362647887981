import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    generated: String
  }

  static targets = ["input"]

  suggest() {
    this.inputTarget.value = this.generatedValue
  }
}

import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["activeEvent"]

  clicked() {
    get(this._event_url_to_visit(), { responseKind: "turbo_stream" })
  }

  _event_url_to_visit() {
    if (this.hasActiveEventTarget) {
      return this.activeEventTargets.at(0).getAttribute("data-event-link")
    }
  }
}

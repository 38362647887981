import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["prompt", "input"]
  static values = { multiple: Boolean, submitOnChange: Boolean, submitOnClose: Boolean }

  connect() {
    this.initialValues = this._selectedValues()
  }

  toggleOpen() {
    const open = this.element.toggleAttribute("open")
    if (!open && this._selectionChanged() && this.submitOnCloseValue) {
      this.inputTarget.form.submit()
    }
  }

  toggleSelected({ currentTarget }) {
    const input = currentTarget.previousElementSibling
    if (this.multipleValue) {
      input.toggleAttribute("disabled")
    } else {
      this.inputTargets.forEach(i => i.toggleAttribute("disabled", i !== input))
      this.promptTarget.innerHTML = currentTarget.querySelector("[data-role='option-template']").innerHTML
    }
    if (this.submitOnChangeValue) {
      this.inputTarget.form.submit()
    } else if (!this.multipleValue) {
      this.toggleOpen()
    }
  }

  clearAll() {
    this.inputTargets.forEach(input => input.toggleAttribute("disabled", true))
  }

  outsideClick({ target }) {
    if (target !== this.element && !this.element.contains(target) && this.element.hasAttribute("open")) {
      this.toggleOpen()
    }
  }

  _selectedValues() {
    return this.inputTargets.filter(i => !i.hasAttribute("disabled")).map(i => i.value)
  }

  _selectionChanged() {
    return JSON.stringify(this.initialValues) !== JSON.stringify(this._selectedValues())
  }
}

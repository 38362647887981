import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { autoOpen: false }

  connect() {
    if (this.autoOpenValue) {
      this.element.showModal()
    }
  }

  handleClick(event) {
    if (event.target === this.element) {
      this.close()
    }
  }

  close() {
    this.element.remove()
  }
}

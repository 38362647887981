import { Controller } from '@hotwired/stimulus'
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

// Delete when feature 2025_q1_redesigned_escalation_procedure_page is enabled everywhere
export default class extends Controller {
  initialize() {
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.saveOrder.bind(this),
      handle: '.sort-handle',
      animation: 500,
      ghostClass: 'tw-opacity-50',
      filter: '.btn, .inactive',
      draggable: '.block:not(.inactive), tr:not(.inactive)'
    })
  }

  saveOrder(event) {
    console.log(event)
    let id = event.item.dataset.id
    let data = new FormData();
    data.append("call_order", event.newIndex + 1)

    Rails.ajax({
      url: this.data.get("url").replace(':id', id),
      type: 'PATCH',
      data: data
    })
  }
}

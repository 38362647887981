import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  async connect() { }

  async openDialog(e) {
    e.preventDefault()
    e.stopImmediatePropagation()

    const info = 'Are you sure? This will delete all occupancies';
    const inputName = 'Delete Resident'
    const inputParams = {}
    inputParams[inputName] = 'checkbox'

    ActiveAdmin.ModalDialog(
      info,
      inputParams,
      async (inputs) => {
        console.log(inputs)
        if (inputs[inputName] == 'on') {
          this.element.action = `${this.element.action}?delete_resident=true`
        }
        this.element.submit()
      }
    )
  }
}
